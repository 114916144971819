import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Circuit breaker";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "circuit-breaker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#circuit-breaker",
        "aria-label": "circuit breaker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Circuit breaker`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#state-of-circuitbreaker"
        }}>{`State of CircuitBreaker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#circuitbreakerclient"
        }}>{`CircuitBreakerClient`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#circuitbreakerrule"
        }}>{`CircuitBreakerRule`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#grouping-circuitbreakers"
        }}>{`Grouping CircuitBreakers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#circuitbreakerbuilder"
        }}>{`CircuitBreakerBuilder`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-circuitbreaker-with-non-armeria-client"
        }}>{`Using CircuitBreaker with non-Armeria client`}</a></li>
    </ul>
    <p>{`In microservice architecture, it's common that various services running on different machines are connected to
each other through remote calls. If one of the services becomes unreachable somehow such as due to network
issues, the client which connects to that service will take long to get a response from it or to fail to
get one. Situation will get even worse if there are many remote calls to such unresponsive service.
You can configure an Armeria client with a circuit breaker to prevent this circumstance. The circuit breaker
can automatically detect failures by continuously updating success and failure events. If the remote service
is unresponsive, it will immediately respond with an error and not make remote calls.
Please refer to `}<a parentName="p" {...{
        "href": "https://martinfowler.com/bliki/CircuitBreaker.html"
      }}>{`CircuitBreaker wiki page`}</a>{` by Martin Fowler and
`}<a parentName="p" {...{
        "href": "https://engineering.linecorp.com/en/blog/detail/76"
      }}>{`LINE Engineering blog post about circuit breaker`}</a>{`
for more information.`}</p>
    <h2 {...{
      "id": "state-of-circuitbreaker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#state-of-circuitbreaker",
        "aria-label": "state of circuitbreaker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`State of `}<inlineCode parentName="h2">{`CircuitBreaker`}</inlineCode></h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` can be one of the following three states:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CLOSED`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Initial state. All requests are treated normally.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`OPEN`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`The state machine enters the `}<inlineCode parentName="li">{`OPEN`}</inlineCode>{` state once the number of failures divided by the total number of
requests exceeds a certain threshold. All requests are blocked off responding with `}<a parentName="li" {...{
              "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
            }}>{`type://FailFastException`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`HALF_OPEN`}</inlineCode>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`After a certain amount of time in the `}<inlineCode parentName="li">{`OPEN`}</inlineCode>{` state, the state machine enters the `}<inlineCode parentName="li">{`HALF_OPEN`}</inlineCode>{` state
which sends a request to find out if the service is still unavailable or not.
If the request succeeds, it enters the `}<inlineCode parentName="li">{`CLOSED`}</inlineCode>{` state. If it fails, it enters the `}<inlineCode parentName="li">{`OPEN`}</inlineCode>{` state again.`}</li>
        </ul>
      </li>
    </ul>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "712",
        "height": "304",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "712",
          "height": "304"
        }}></rect>{`
  `}<polygon parentName="svg" {...{
          "points": "432,52 424,56 432,60",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "466",
          "y": "76"
        }}>{`failed`}</text>{`
  `}<text parentName="svg" {...{
          "x": "522",
          "y": "76"
        }}>{`again`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "312,92 316,80 320,92",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "10",
          "y": "156"
        }}>{`under`}</text>{`
  `}<text parentName="svg" {...{
          "x": "58",
          "y": "156"
        }}>{`threshold`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "72,196 80,196 76,208",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "162",
          "y": "220"
        }}>{`exceeded`}</text>{`
  `}<text parentName="svg" {...{
          "x": "234",
          "y": "220"
        }}>{`threshold`}</text>{`
  `}<text parentName="svg" {...{
          "x": "434",
          "y": "220"
        }}>{`timed`}</text>{`
  `}<text parentName="svg" {...{
          "x": "482",
          "y": "220"
        }}>{`out`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "552,228 560,232 552,236",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "152,260 144,264 152,268",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "226",
          "y": "284"
        }}>{`back`}</text>{`
  `}<text parentName="svg" {...{
          "x": "266",
          "y": "284"
        }}>{`to`}</text>{`
  `}<text parentName="svg" {...{
          "x": "290",
          "y": "284"
        }}>{`normal`}</text>{`
  `}<path parentName="svg" {...{
          "d": "M 352,272 A 16,16 0,0,0 352,288",
          "className": "nofill"
        }}></path>{`
  `}<text parentName="svg" {...{
          "x": "354",
          "y": "284"
        }}>{`request`}</text>{`
  `}<text parentName="svg" {...{
          "x": "418",
          "y": "284"
        }}>{`succeeded`}</text>{`
  `}<path parentName="svg" {...{
          "d": "M 488,272 A 16,16 0,0,1 488,288",
          "className": "nofill"
        }}></path>{`
  `}<text parentName="svg" {...{
          "x": "338",
          "y": "44"
        }}>{`OPEN`}</text>{`
  `}<text parentName="svg" {...{
          "x": "50",
          "y": "252"
        }}>{`CLOSED`}</text>{`
  `}<text parentName="svg" {...{
          "x": "594",
          "y": "252"
        }}>{`HALF`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "624",
          "y1": "256",
          "x2": "632",
          "y2": "256",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "642",
          "y": "252"
        }}>{`OPEN`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "8",
            "x2": "420",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "8",
            "x2": "284",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "420",
            "y1": "8",
            "x2": "420",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "284",
            "y1": "72",
            "x2": "420",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "388",
            "y1": "72",
            "x2": "388",
            "y2": "232",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "388",
            "y1": "232",
            "x2": "552",
            "y2": "232",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "432",
            "y1": "56",
            "x2": "588",
            "y2": "56",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "588",
            "y1": "56",
            "x2": "588",
            "y2": "216",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "564",
            "y1": "216",
            "x2": "700",
            "y2": "216",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "564",
            "y1": "216",
            "x2": "564",
            "y2": "280",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "700",
            "y1": "216",
            "x2": "700",
            "y2": "280",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "152",
            "y1": "264",
            "x2": "564",
            "y2": "264",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "564",
            "y1": "280",
            "x2": "700",
            "y2": "280",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "316",
            "y1": "92",
            "x2": "316",
            "y2": "232",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "44",
            "y1": "168",
            "x2": "76",
            "y2": "168",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "44",
            "y1": "168",
            "x2": "44",
            "y2": "216",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "76",
            "y1": "168",
            "x2": "76",
            "y2": "196",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "216",
            "x2": "140",
            "y2": "216",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "216",
            "x2": "4",
            "y2": "280",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "140",
            "y1": "216",
            "x2": "140",
            "y2": "280",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "140",
            "y1": "232",
            "x2": "316",
            "y2": "232",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "280",
            "x2": "140",
            "y2": "280",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <h2 {...{
      "id": "circuitbreakerclient",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#circuitbreakerclient",
        "aria-label": "circuitbreakerclient permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`CircuitBreakerClient`}</inlineCode></h2>
    <p>{`Armeria provides two different client implementations depending on the
`}<a parentName="p" {...{
        "href": "type://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
      }}>{`type://Request`}</a>{` and `}<a parentName="p" {...{
        "href": "type://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`type://Response`}</a>{` types:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
        }}>{`type://CircuitBreakerClient`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreakerRpcClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRpcClient.html"
        }}>{`type://CircuitBreakerRpcClient`}</a></li>
    </ul>
    <p>{`Let's use `}<a parentName="p" {...{
        "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
      }}>{`type://CircuitBreakerClient`}</a>{` to find out what we can do.
You can use the `}<inlineCode parentName="p">{`decorator()`}</inlineCode>{` method in `}<a parentName="p" {...{
        "href": "type://WebClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientBuilder.html"
      }}>{`type://WebClientBuilder`}</a>{` to build a `}<a parentName="p" {...{
        "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
      }}>{`type://CircuitBreakerClient`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreaker;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerClient;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRule;
import com.linecorp.armeria.common.AggregatedHttpResponse;
import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;

CircuitBreakerRule rule = CircuitBreakerRule.builder()
                                            .onServerErrorStatus()
                                            .onException()
                                            .thenFailure();

WebClient client = WebClient
        .builder(...)
        .decorator(CircuitBreakerClient.builder(rule)
                                       .newDecorator())
        .build();

AggregatedHttpResponse res = client.execute(...).aggregate().join(); // Send requests on and on.
`}</code></pre>
    <p>{`Now, the `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` can track the number of success or failure events depending on the
`}<a parentName="p" {...{
        "href": "typeplural://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`typeplural://Response`}</a>{`. The `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` will enter `}<inlineCode parentName="p">{`OPEN`}</inlineCode>{`, when the number of failures divided
by the total number of `}<a parentName="p" {...{
        "href": "typeplural://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
      }}>{`typeplural://Request`}</a>{` exceeds the failure rate.
Then the `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` will immediately get `}<a parentName="p" {...{
        "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
      }}>{`type://FailFastException`}</a>{` by the `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{`.`}</p>
    <h2 {...{
      "id": "circuitbreakerrule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#circuitbreakerrule",
        "aria-label": "circuitbreakerrule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`CircuitBreakerRule`}</inlineCode></h2>
    <p>{`How does a `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` know whether a `}<a parentName="p" {...{
        "href": "type://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`type://Response`}</a>{` is successful or not?
`}<a parentName="p" {...{
        "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
      }}>{`type://CircuitBreakerRule`}</a>{` does the job. In the example above, if the status of a `}<a parentName="p" {...{
        "href": "type://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`type://Response`}</a>{` is `}<inlineCode parentName="p">{`5xx`}</inlineCode>{`
or an `}<inlineCode parentName="p">{`Exception`}</inlineCode>{` is raised during the call, the count of failure is increased.
You can have your own `}<inlineCode parentName="p">{`rule`}</inlineCode>{` by building `}<a parentName="p" {...{
        "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
      }}>{`type://CircuitBreakerRule`}</a>{`.
The following example builds a `}<a parentName="p" {...{
        "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
      }}>{`type://CircuitBreakerRule`}</a>{` that fails when an `}<inlineCode parentName="p">{`Exception`}</inlineCode>{`
is raised or the status is `}<inlineCode parentName="p">{`5xx`}</inlineCode>{`, succeeds when the status is `}<inlineCode parentName="p">{`2xx`}</inlineCode>{` and ignores others.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientRequestContext;
import com.linecorp.armeria.client.UnprocessedRequestException;
import com.linecorp.armeria.common.HttpStatus;
import com.linecorp.armeria.common.HttpStatusClass;

final CircuitBreakerRule myRule = 
        CircuitBreakerRule.of(
                // A failure if an Exception is raised.
                CircuitBreakerRule.onException(), 
                // Neither a success nor a failure because the request has not been handled by the server.
                CircuitBreakerRule.builder()
                                  .onUnprocessed()
                                  .thenIgnore(),
                // A failure if the response is 5xx.
                CircuitBreakerRule.onServerErrorStatus(),
                // A success if the response is 2xx.
                CircuitBreakerRule.builder()
                                  .onStatusClass(HttpStatusClass.SUCCESS)
                                  .thenSuccess(),
                // Neither a success nor a failure. Do not take this response into account.
                CircuitBreakerRule.builder().thenIgnore());
`}</code></pre>
    <p>{`If you need to determine whether the request was successful by looking into the response content,
you should build `}<a parentName="p" {...{
        "href": "type://CircuitBreakerRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleWithContent.html"
      }}>{`type://CircuitBreakerRuleWithContent`}</a>{` and specify it when you create an
`}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` using `}<a parentName="p" {...{
        "href": "type://CircuitBreakerClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClientBuilder.html"
      }}>{`type://CircuitBreakerClientBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRuleWithContent;

final CircuitBreakerRuleWithContent<HttpResponse> myRule =
        CircuitBreakerRuleWithContent.of(
                CircuitBreakerRuleWithContent.<HttpResponse>builder()
                                             .onUnprocessed()
                                             .thenIgnore(),
                CircuitBreakerRuleWithContent.<HttpResponse>builder()
                                             .onException()
                                             .thenFailure(),
                CircuitBreakerRuleWithContent.<HttpResponse>builder()
                                             .onResponse(response -> response.aggregate().thenApply(content -> {
                                                 return content.equals("Failure");
                                             }))
                                             .thenFailure());

WebClient client = WebClient
        .builder(...)
        .decorator(CircuitBreakerClient.builder(myRule) // Specify the rule
                                       .newDecorator())
        .build();

AggregatedHttpResponse res = client.execute(...).aggregate().join();
`}</code></pre>
    <h2 {...{
      "id": "grouping-circuitbreakers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#grouping-circuitbreakers",
        "aria-label": "grouping circuitbreakers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Grouping `}<inlineCode parentName="h2">{`CircuitBreaker`}</inlineCode>{`s`}</h2>
    <p>{`In the very first example above, a single `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` was used to track the events. However,
in many cases, you will want to use different `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` for different endpoints. For example, there
might be an API which performs heavy calculation which fails often. On the other hand, there can be another API
which is not resource hungry and this is not likely to fail.
Having one `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` that tracks all the success and failure does not make sense in this scenario.
It's even worse if the client connects to the services on different machines.
When one of the remote services is down, your `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` will probably be `}<inlineCode parentName="p">{`OPEN`}</inlineCode>{` state although
you can connect to other services.
Therefore, Armeria provides various ways that let users group the range of circuit breaker instances.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Group by host: a single `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{` is used for each remote host.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRpcClient;
import com.linecorp.armeria.common.RpcResponse;

final CircuitBreakerRule httpRule = CircuitBreakerRule.builder()
                                                      .onServerErrorStatus()
                                                      .onException()
                                                      .thenFailure();
final CircuitBreakerRule rpcRule = CircuitBreakerRule.onException();

// Create a CircuitBreaker with the key name
final Function<String, CircuitBreaker> factory = key -> CircuitBreaker.of("my-cb-" + key);

// Create CircuitBreakers per host (a.com, b.com ...)
CircuitBreakerClient.newPerHostDecorator(factory, httpRule);
CircuitBreakerRpcClient.newPerHostDecorator(factory, rpcRule);
// The names of the created CircuitBreaker: my-cb-a.com, my-cb-b.com, ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Group by method: a single `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{` is used for each method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Create CircuitBreakers per method
CircuitBreakerClient.newPerMethodDecorator(factory, httpRule);
// The names of the created CircuitBreaker: my-cb-GET, my-cb-POST, ...

CircuitBreakerRpcClient.newPerMethodDecorator(factory, rpcRule);
// The names of the created CircuitBreaker: my-cb-methodA, my-cb-methodB, ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Group by host and method: a single `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{` is used for each method and host combination.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Create a CircuitBreaker with the host and method name
final BiFunction<String, String, CircuitBreaker> factory =
        (host, method) -> CircuitBreaker.of("my-cb-" + host + '#' + method);
// Create CircuitBreakers per host and method
CircuitBreakerClient.newDecorator(CircuitBreakerMapping.perHostAndMethod(factory), httpRule);
// The names of the created CircuitBreaker: my-cb-a.com#GET,
// my-cb-a.com#POST, my-cb-b.com#GET, my-cb-b.com#POST, ...

CircuitBreakerRpcClient.newDecorator(CircuitBreakerMapping.perHostAndMethod(factory), rpcRule);
// The names of the created CircuitBreaker: my-cb-a.com#methodA,
// my-cb-a.com#methodB, my-cb-b.com#methodA, my-cb-b.com#methodB, ...
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "circuitbreakerbuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#circuitbreakerbuilder",
        "aria-label": "circuitbreakerbuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`CircuitBreakerBuilder`}</inlineCode></h2>
    <p>{`We have used static factory methods in `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` interface to create a `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` so far.
If you use `}<a parentName="p" {...{
        "href": "type://CircuitBreakerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerBuilder.html"
      }}>{`type://CircuitBreakerBuilder`}</a>{`, you can configure the parameters which decide
`}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{`'s behavior. Here are the parameters:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`name`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The name of the `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`failureRateThreshold`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The threshold that changes `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{`'s state to `}<inlineCode parentName="li">{`OPEN`}</inlineCode>{` when the number of failed
`}<a parentName="li" {...{
              "href": "typeplural://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
            }}>{`typeplural://Request`}</a>{` divided by the number of total `}<a parentName="li" {...{
              "href": "typeplural://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
            }}>{`typeplural://Request`}</a>{` exceeds it.
The default value is `}<inlineCode parentName="li">{`0.5`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`minimumRequestThreshold`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The minimum number of `}<a parentName="li" {...{
              "href": "typeplural://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
            }}>{`typeplural://Request`}</a>{` to detect failures. The default value is `}<inlineCode parentName="li">{`10`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`trialRequestInterval`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The duration that a `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{` remains in `}<inlineCode parentName="li">{`HALF_OPEN`}</inlineCode>{` state. All requests are blocked off
responding with `}<a parentName="li" {...{
              "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
            }}>{`type://FailFastException`}</a>{` during this state. The default value is `}<inlineCode parentName="li">{`3`}</inlineCode>{` seconds.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`circuitOpenWindow`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The duration that a `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{` remains in `}<inlineCode parentName="li">{`OPEN`}</inlineCode>{` state. All `}<a parentName="li" {...{
              "href": "typeplural://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
            }}>{`typeplural://Request`}</a>{` are blocked
off responding with `}<a parentName="li" {...{
              "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
            }}>{`type://FailFastException`}</a>{` during this state. The default value is `}<inlineCode parentName="li">{`10`}</inlineCode>{` seconds.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`counterSlidingWindow`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The duration of a sliding window that a `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{` counts successful and failed
`}<a parentName="li" {...{
              "href": "typeplural://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
            }}>{`typeplural://Request`}</a>{` in it. The default value is `}<inlineCode parentName="li">{`20`}</inlineCode>{` seconds.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`counterUpdateInterval`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The duration that a `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{` stores events in a bucket. The default value is `}<inlineCode parentName="li">{`1`}</inlineCode>{` second.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`listeners`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The listeners which are notified by a `}<a parentName="li" {...{
              "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
            }}>{`type://CircuitBreaker`}</a>{` when an event occurs. The events are one of
`}<inlineCode parentName="li">{`stateChanged`}</inlineCode>{`, `}<inlineCode parentName="li">{`eventCountUpdated`}</inlineCode>{` and `}<inlineCode parentName="li">{`requestRejected`}</inlineCode>{`. You can use
`}<a parentName="li" {...{
              "href": "type://CircuitBreakerListener#metricCollecting()"
            }}>{`type://CircuitBreakerListener#metricCollecting()`}</a>{` to export metrics:`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerListener;

import com.linecorp.armeria.common.Flags;

final CircuitBreakerListener listener =
        CircuitBreakerListener.metricCollecting(Flags.meterRegistry());
final CircuitBreakerBuilder builder = CircuitBreaker.builder()
                                                    .listener(listener);
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "using-circuitbreaker-with-non-armeria-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-circuitbreaker-with-non-armeria-client",
        "aria-label": "using circuitbreaker with non armeria client permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<inlineCode parentName="h2">{`CircuitBreaker`}</inlineCode>{` with non-Armeria client`}</h2>
    <p><a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{` API is designed to be framework-agnostic and thus can be used with any non-Armeria
clients:`}</p>
    <ol>
      <li parentName="ol">{`Create a `}<a parentName="li" {...{
          "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
        }}>{`type://CircuitBreaker`}</a>{`.`}</li>
      <li parentName="ol">{`Guard your client calls with `}<inlineCode parentName="li">{`CircuitBreaker.tryRequest()`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Update the state of `}<a parentName="li" {...{
          "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
        }}>{`type://CircuitBreaker`}</a>{` by calling `}<a parentName="li" {...{
          "href": "type://CircuitBreaker#onSuccess():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html#onSuccess()"
        }}>{`type://CircuitBreaker#onSuccess()`}</a>{` or
`}<a parentName="li" {...{
          "href": "type://CircuitBreaker#onFailure():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html#onFailure()"
        }}>{`type://CircuitBreaker#onFailure()`}</a>{` depending on the outcome of the client call.`}</li>
    </ol>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`private final CircuitBreaker cb = CircuitBreaker.of("some-client");
private final SomeClient client = ...;

public void sendRequestWithCircuitBreaker() {
    if (!cb.tryRequest()) {
        throw new RuntimeException();
    }

    boolean success = false;
    try {
        success = client.sendRequest();
    } finally {
        if (success) {
            cb.onSuccess();
        } else {
            cb.onFailure();
        }
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      